import type { Rolling } from '../types'

export function useBonusRolling(rollingData: ComputedRef<Rolling | null>) {
  const stFetch = useRawStFetch()
  const isCurrentRollingLoading = ref<boolean>(false)

  function setCurrentRolling() {
    isCurrentRollingLoading.value = true

    try {
      stFetch('/user-bonus-rolling/set-current', {
        method: 'post',
        body: {
          userBonusRollingId: rollingData.value?.id || 0,
        },
      })
    } catch (e) {
      console.error(e)
    } finally {
      isCurrentRollingLoading.value = false
    }
  }
  const rollingStatus = computed(() =>
    rollingData.value ? rollingData.value.status : null,
  )
  const isRollingStatusNewOrPaused = computed(() =>
    rollingData.value
      ? rollingData.value.status === 'new' ||
        rollingData.value.status === 'paused'
      : null,
  )
  const isRollingStatusInProgress = computed(() =>
    rollingData.value ? rollingData.value.status === 'inProgress' : null,
  )

  return {
    setCurrentRolling,
    isCurrentRollingLoading,
    isRollingStatusNewOrPaused,
    isRollingStatusInProgress,
    rollingStatus,
  }
}
